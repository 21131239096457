// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// dotenv.config();
export const firebaseConfig = {
    apiKey: "AIzaSyCv2w7HK_2zcv872ZTeCdngRAvThF3PWqQ",
    authDomain: "myportfolio-1843e.firebaseapp.com",
    projectId: "myportfolio-1843e",
    storageBucket: "myportfolio-1843e.appspot.com",
    messagingSenderId: "829428564095",
    appId: "1:829428564095:web:dca0de433c936c3c10bf4a",
    measurementId: "G-75FGJ61DR6"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);