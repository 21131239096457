
import React from "react";

const PageLoader = () => {
    return (
        <div className="pageLoader">
            <h1>LOADING....</h1>
        </div>
    )
}

export default PageLoader