import React from "react";

const ComponentLoader = () => {
    return (
        <div className="componentLoader">
            <h1>LOADING....</h1>
        </div>
    )
}

export default ComponentLoader